import React, { useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { signIn, execute } from "./youtube/client";
import { sampleAnalytics } from "./youtube/sample";
import VideosTable from "./VideosTable";

// will use useEffect hook to get data from client.js
// useReducer / redux

function App() {
  const [signedIn, setSignedIn] = useState(false);

  const handleSignIn = () => {
    signIn()
      .then(() => {
        setSignedIn(true);
      });
  };
  const handleExecute = () => {
    sampleAnalytics().then(console.log)
  };
  return (
    <div className="App">
      <header className="App-header">
        <p>
          {signedIn ? (
            <button onClick={handleExecute}>Run Query</button>
          ) : (
            <button onClick={handleSignIn}>Sign in to Google</button>
          )}
        </p>
      </header>
      <VideosTable />
    </div>
  );
}

export default App;
