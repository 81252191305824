import React, { useState } from 'react';
import { useTable } from 'react-table';
import PropTypes from 'prop-types';
import { sampleAnalytics } from './youtube/sample';
import Table from './common/Table';

const VideosTable = () => {
  const columns = React.useMemo(
    () => [
      {
        Header: 'Thumbnails' 
      },
      {
        Header: 'Video Engagement',
        columns: [
          {
            Header: 'Average View Duration',
            accessor: 'averageViewDuration',
          },
          {
            Header: 'Views',
            accessor: 'views',
          },
          {
            Header: 'Lifetime',
            accessor: 'lifetime',
          },
          {
            Header: 'Subscriber gained',
            accessor: 'subscriberGained',
          }
        ],
      },
      {
        Header: 'Ad Total',
        columns: [
          {
            Header: 'Ad Group',
            accessor: 'adGroup',
          },
          {
            Header: 'Cost',
            accessor: 'cost',
          },
          {
            Header: 'Impression',
            accessor: 'impression',
          },
          {
            Header: 'Views',
            accessor: 'adViews',
          },
          {
            Header: 'Earned Views',
            accessor: 'earnedViews',
          },
          {
            Header: 'View Rate',
            accessor: 'viewRate',
          },
          {
            Header: 'Lifetime',
            accessor: 'adLifetime',
          },
        ],
      },
    ],
    []
  )

  const { rows: data } = sampleAnalytics();

  return (
    <Table columns={columns} data={data} />
  )
};

VideosTable.propTypes = {

};

export default VideosTable;