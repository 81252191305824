import { gapi } from 'gapi-script';

// performs OAuth login to Google
export const signIn = () => {
	return gapi.auth2.getAuthInstance().signIn()
		.then(() => {
			console.log("Sign-in successful");
		})
		.catch(err => {
			console.error("Error signing in", err);
		});
};

// Make sure the client is loaded and sign-in is complete before calling this method.
export const execute = () => {
	return gapi.client.youtubeAnalytics.reports.query({
		"ids": "channel==MINE",
		"startDate": "2020-01-01",
		"endDate": "2020-04-02",
		"metrics": "estimatedMinutesWatched,views,subscribersGained,averageViewDuration",
		"dimensions": "video",
		"maxResults": 10,
		"sort": "-estimatedMinutesWatched"
	})
	.then(response => {
		// Handle the results here (response.result has the parsed body).
		console.log("Response", response);
	})
	.catch(err => {
		console.error("Execute error", err);
	});
}

const init = () => {
	gapi.client.init({
		discoveryDocs: [
			'https://youtubeanalytics.googleapis.com/$discovery/rest?version=v2'
		],
		scope: [
			'https://www.googleapis.com/auth/youtube.readonly',
			'https://www.googleapis.com/auth/yt-analytics.readonly'
		].join(' '),
		client_id: "839380471344-fqj8o69a7ulvg0slssved359pv8jmsep.apps.googleusercontent.com"
	});
};

gapi.load("client:auth2", init);
